import React, { Suspense, useEffect } from 'react';
import { ConfigProvider, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react'; // Importing observer and inject from mobx-react
import Dashboard from './components/layout/dashboard';
import AuthGuard from './components/AuthGuard';
import './assets/styles/global.css';
import AskForHelp from './components/Help/AskForHelp';

const App = ({ tokenStore }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!tokenStore.value || `${tokenStore.value}`.trim() === '') {
      i18n.changeLanguage('en');
    }
  }, [tokenStore.value, i18n]);

  message.config({
    top: 100,
    duration: 2,
    maxCount: 3,
  });

  return (
    <Suspense fallback="loading...">
      <div className="main-wrapper">
        <ConfigProvider

          //       theme={{
          //         token: {
          //           // Seed Token
          //           colorPrimary: '#001529',

          // }
          //       }}

          direction={i18n.language === 'en' ? 'ltr' : 'rtl'}
        >
          <AuthGuard>
            <Dashboard />
          </AuthGuard>
        </ConfigProvider>
      </div>
    </Suspense>
  );
};

// Injecting the tokenStore and making the component an observer
export default inject('tokenStore')(observer(App));
