import { Modal, Upload, message } from 'antd';
import React, { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';

import { axiosInstanceForImage } from './axios';
import { inject, observer } from 'mobx-react';

function ImagePreview({
  form,
  formName,
  imgUrl = '',
  numberOfUpload = 1,
  imageUploader = true,
  tokenStore,
  // image uploader check if using form data to server or request to server and get the URL.
}) {
  const axios = axiosInstanceForImage(tokenStore.value);

  const requestImageUpload = (formData) => axios.post('/imageUpload', formData);

  const { t } = useTranslation();
  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

  const [isLoading, setIsLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (file.url) {
      setPreviewImage(file.url);
    } else {
      setPreviewImage(file.thumbUrl);
    }
    setPreviewOpen(true);

    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = (info) => {
    if (info.file.size > MAX_FILE_SIZE) {
      message.error('File size must be smaller than 10MB');
      // Prevent upload
    } else {
      const fileList = [...info.fileList];

      if (!imageUploader) {
        setFileList(fileList);
        form.setFieldsValue({
          [formName]: fileList.map((file) => file.originFileObj),
        });
      }
    }
  };

  const handleDelete = (fileRemoved) => {
    if (fileList.length == 1) {
      setFileList([]);
      form.setFieldsValue({
        [formName]: '',
      });
    } else {
      const filterImages = fileList.filter((file) => file.uid != fileRemoved.uid);
      setFileList(filterImages);
      form.setFieldsValue({
        [formName]: filterImages,
      });
    }
  };

  useEffect(() => {
    if (imgUrl && fileList?.length < 1) {
      if (typeof imgUrl === 'object') {
        const newImage = [];
        imgUrl.forEach((img) => {
          newImage.push({
            uid: img.id,
            name: 'preview.png',
            status: 'done',
            url: img.url,
          });
        });
        setFileList(newImage);
        form.setFieldsValue({
          [formName]: newImage,
        });
      } else {
        // it means there's one image
        setFileList([
          {
            uid: '-2',
            name: 'preview.png',
            status: 'done',
            url: imgUrl,
          },
        ]);
      }
    }
  }, []);

  return (
    <Fragment>
      <Upload
        accept="image/*"
        multiple={true}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={handleDelete}
        maxCount={numberOfUpload}
        customRequest={async (options) => {
          const { onSuccess, onError, file } = options;
          setIsLoading(true);
          try {
            if (imageUploader) {
              const formData = new FormData();
              formData.append('image', file);
              await requestImageUpload(formData).then((res) => {
                if (numberOfUpload == 1) {
                  form.setFieldsValue({
                    [formName]: res.data,
                  });

                  setFileList([
                    {
                      uid: '-2',
                      name: 'preview.png',
                      status: 'done',
                      url: res.data,
                    },
                  ]);
                } else {
                  const arrayImage = form.getFieldValue(formName) || [];
                  arrayImage.push({
                    uid: Math.random(),
                    name: 'preview.png',
                    status: 'done',
                    url: res,
                  });
                  form.setFieldsValue({
                    [formName]: arrayImage,
                  });

                  setFileList(arrayImage);
                }
              });
            }
            onSuccess('Ok');
          } catch (err) {
            console.log(err);
            onError({ err });
          }
          setIsLoading(false);
        }}
      >
        {fileList.length >= numberOfUpload ? null : (
          <div>
            {isLoading ? <LoadingOutlined /> : <UploadOutlined />}
            <div
              style={{
                marginTop: 8,
              }}
            >
              {t('upload')}
            </div>
          </div>
        )}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </Fragment>
  );
}


export default inject('tokenStore')(observer(ImagePreview));
