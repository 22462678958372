import { Button, Form, Input, Modal } from 'antd'
import React from 'react'

const UpdatePasswordModal = ({onClickUpdatePassword,setVisible,visible,resetPasswordLoading}) => {
  return (
    <Modal
        maskClosable={false}
        destroyOnClose
        title="Update Password"
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form layout="vertical" onFinish={onClickUpdatePassword}>       
          <Form.Item
            label="Old Password"
            name="old_password"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
              {
                pattern: new RegExp('.{8,}'),
                message: 'Use 8 characters or more for your password',
              },
            ]}
          >
            <Input type="password" placeholder="E.g. ABCD1234" />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="new_password"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
              {
                pattern: new RegExp('.{8,}'),
                message: 'Use 8 characters or more for your password',
              },
            ]}
          >
            <Input type="password" placeholder="E.g. DCBA4312" />
          </Form.Item>
          <Button
            type="primary"
            loading={resetPasswordLoading}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form>
      </Modal>
  )
}


export default UpdatePasswordModal
